@import "./_variables";

#hero {
  width: 100%;
  height: calc(100vh - 85px);
  position: relative;
  background: url("../img/hero-bg.jpg") top center;
  background-size: cover;
}
#hero:before {
  content: "";
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
#hero .container {
  padding-top: 80px;
}
#hero h1 {
  margin: 0;
  font-size: 64px;
  font-weight: 700;
  line-height: 64px;
  color: #0e3a5a;
  font-family: "Raleway", sans-serif;
}
#hero h2 {
  color: #5e5e5e;
  margin: 10px 0 0 0;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 1.1px;
}
#hero .text-highlight {
  color: $color-primary;
}
#hero .icon-boxes {
  margin-top: 100px;
}
#hero .icon-box {
  width: 100%;
  padding: 50px 30px;
  position: relative;
  text-align: center;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(18, 66, 101, 0.08);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}
#hero .icon-box .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
  color: #124265;
  transition: 0.3s;
}
#hero .icon-box .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}
#hero .icon-box .icon {
  margin-bottom: 20px;
  padding-top: 10px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  font-size: 36px;
  line-height: 1;
  color: $color-primary;
}
#hero .icon-box:hover {
  transform: scale(1.08);
}
#hero .icon-box:hover .title {
  color: $color-primary;
}
@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}
@media (max-width: 1200px) {
  #hero {
    height: auto;
  }
}
@media (max-width: 992px) {
  #hero h1 {
    font-size: 52px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 28px;
  }
  #hero .icon-boxes {
    margin-top: 50px;
  }
}
@media (max-width: 480px) {
  #hero h1 {
    font-size: 38px;
  }
  #hero h2 {
    font-size: 16px;
  }
}
